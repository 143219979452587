// extracted by mini-css-extract-plugin
export var bodyBase = "events-approval-modal-module--body-base--nVFzo events-approval-modal-module--site-font--PtWes";
export var bodyLarge = "events-approval-modal-module--body-large--ahfsn events-approval-modal-module--body-base--nVFzo events-approval-modal-module--site-font--PtWes";
export var bodyLargeBold = "events-approval-modal-module--body-large-bold--IbQNj events-approval-modal-module--body-base--nVFzo events-approval-modal-module--site-font--PtWes";
export var bodyRegular = "events-approval-modal-module--body-regular--2VXaz events-approval-modal-module--body-base--nVFzo events-approval-modal-module--site-font--PtWes";
export var bodyRegularBold = "events-approval-modal-module--body-regular-bold--p66D4 events-approval-modal-module--body-base--nVFzo events-approval-modal-module--site-font--PtWes";
export var bodySmall = "events-approval-modal-module--body-small--8BZPz events-approval-modal-module--body-base--nVFzo events-approval-modal-module--site-font--PtWes";
export var bodySmallBold = "events-approval-modal-module--body-small-bold---7fr9 events-approval-modal-module--body-base--nVFzo events-approval-modal-module--site-font--PtWes";
export var borderTop = "events-approval-modal-module--border-top--L+mu-";
export var breakWordContainer = "events-approval-modal-module--break-word-container--kJCzT";
export var buttonIconBase = "events-approval-modal-module--button-icon-base--AWmaZ";
export var clickLink = "events-approval-modal-module--click-link--5LPJJ";
export var dropdownBase = "events-approval-modal-module--dropdown-base--qNc67";
export var dropdownSelectBase = "events-approval-modal-module--dropdown-select-base--8HFhv events-approval-modal-module--text-input--MRAkf";
export var emailTemplateContainer = "events-approval-modal-module--email-template-container--WAazj";
export var errorIcon = "events-approval-modal-module--error-icon--B64ZR";
export var errorText = "events-approval-modal-module--error-text--YwR6w";
export var flexCol = "events-approval-modal-module--flex-col--pZEBU";
export var formErrorMessage = "events-approval-modal-module--form-error-message--TGN9m";
export var h3 = "events-approval-modal-module--h3--aEOqA";
export var h4 = "events-approval-modal-module--h4--MkRhC";
export var hoverLink = "events-approval-modal-module--hover-link--foe-K";
export var hoverRow = "events-approval-modal-module--hover-row---rSiP";
export var membershipContainer = "events-approval-modal-module--membership-container--Ug5ne events-approval-modal-module--flex-col--pZEBU events-approval-modal-module--primary-border--OhGR3";
export var membershipHeader = "events-approval-modal-module--membership-header--7dRy-";
export var membershipHeading = "events-approval-modal-module--membership-heading--nqH-D";
export var membershipLabel = "events-approval-modal-module--membership-label--HJglk";
export var modalContent = "events-approval-modal-module--modal-content--VZ5UL";
export var moreFiltersBorderClass = "events-approval-modal-module--more-filters-border-class--512pW";
export var optionalMessageBox = "events-approval-modal-module--optional-message-box--nxzUn";
export var pageBg = "events-approval-modal-module--page-bg--IKYZ-";
export var pointer = "events-approval-modal-module--pointer--13QeK";
export var primaryBorder = "events-approval-modal-module--primary-border--OhGR3";
export var shadowBoxLight = "events-approval-modal-module--shadow-box-light--2h4Tg";
export var siteFont = "events-approval-modal-module--site-font--PtWes";
export var slideDownAndFade = "events-approval-modal-module--slideDownAndFade--aHa0e";
export var slideLeftAndFade = "events-approval-modal-module--slideLeftAndFade--mxQk6";
export var slideRightAndFade = "events-approval-modal-module--slideRightAndFade--LX+Fg";
export var slideUpAndFade = "events-approval-modal-module--slideUpAndFade--YdMxE";
export var statusDecoration = "events-approval-modal-module--status-decoration--DChLd";
export var textInput = "events-approval-modal-module--text-input--MRAkf";
export var textInverted = "events-approval-modal-module--text-inverted--TMoJp";
export var textMediumDark = "events-approval-modal-module--text-medium-dark--FPYA3";
export var tooltipFont = "events-approval-modal-module--tooltipFont--Xe3MS";
export var unstyledButton = "events-approval-modal-module--unstyled-button--0nt33";