import React from 'react'
import Layout from 'src/components/layout/layout'
import SEO from 'src/components/seo'
import { usePrivateRoute } from 'src/utils/auth'
import Events from 'src/components/events/events'
import { Router, RouteComponentProps } from '@reach/router'

const EventsPage = () => {
  usePrivateRoute()
  return (
    <Router basepath="/tournaments">
      <EventsRoute path="/:tournamentId" />
    </Router>
  )
}

interface EventsRouteProps extends RouteComponentProps {
  tournamentId?: string
}

const EventsRoute: React.FC<EventsRouteProps> = ({ tournamentId }) => {
  return (
    <Layout>
      <SEO title="Events" />
      <Events tournamentId={tournamentId} />
    </Layout>
  )
}

export default EventsPage
