import { useCallback, useEffect, useMemo, useState } from 'react'
import { GetEvents_tournament_events as Event } from 'src/graphql-types/GetEvents'
import { SanctionStatus } from 'src/graphql-types/globalTournamentTypes'

const useApprovalToggle = (events?: Event[]) => {
  const [approvedStates, setApprovedStates] = useState<{ [key: string]: boolean | undefined }>({})

  const eventApprovedStates = useMemo(() => {
    return (
      events?.reduce((c, e) => {
        if (e.sanctionStatus !== SanctionStatus.SUBMITTED) {
          c[e.id] = e.sanctionStatus === SanctionStatus.APPROVED
        }
        return c
      }, {}) ?? {}
    )
  }, [events])

  const reset = useCallback(() => setApprovedStates(eventApprovedStates), [
    eventApprovedStates,
    setApprovedStates
  ])

  const hasChanges = useMemo(() => {
    return Object.entries(eventApprovedStates).some(([k, v]) => approvedStates[k] !== v)
  }, [eventApprovedStates, approvedStates])

  // All events must be either approved or declined
  const publishable = useMemo(() => {
    return (
      !!events &&
      Object.values(approvedStates).filter(e => e !== undefined).length === events.length
      // && Object.values(approvedStates).filter(e => e === false).length !== events.length
      // ^^^ Add this back if we need at least one event to be accepted to publish
    )
  }, [events, approvedStates])

  const updateApproval = useCallback(
    (event: string, approved?: boolean) => {
      setApprovedStates({ ...approvedStates, [event]: approved })
    },
    [approvedStates, setApprovedStates]
  )

  const approveAll = useCallback(() => {
    const all = {}
    events?.forEach(e => (all[e.id] = true))
    setApprovedStates(all)
  }, [setApprovedStates, events])

  const declineAll = useCallback(() => {
    const all = {}
    events?.forEach(e => (all[e.id] = false))
    setApprovedStates(all)
  }, [setApprovedStates, events])

  const getApprovedIds = useCallback(() => {
    const states = Object.entries(approvedStates)
    return {
      approved: states.filter(([_, approved]) => approved).map(([id]) => id),
      declined: states.filter(([_, approved]) => approved === false).map(([id]) => id)
    }
  }, [approvedStates])

  return {
    getApprovedIds,
    updateApproval,
    publishable,
    approvedStates,
    approveAll,
    declineAll,
    reset,
    eventApprovedStates,
    hasChanges
  }
}

export default useApprovalToggle
