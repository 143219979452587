import { useMutation } from '@apollo/client'
import { Panel } from '@clubspark-react/clubspark-react-tools'
import { Grid } from '@material-ui/core'
import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { tournamentsClient } from 'src/apollo/client'
import {
  GetEvents_tournament_featureSettings as FeatureConfig
} from 'src/graphql-types/GetEvents'
import { SetTournamentFeature, SetTournamentFeatureVariables } from 'src/graphql-types/SetTournamentFeature'
import { userIsGlobalAdmin } from 'src/utils/auth'
import { Switch } from '../../formik-fields/formik-fields'
import { SizedGridContent } from '../events'
import { SET_TOURNAMENT_FEATURE } from '../events-queries'
import * as styles from '../events.module.less'

interface TournamentConfigurationProps {
    tournamentId: string
    features: FeatureConfig[]
  }
  
const TournamentConfiguration: React.FC<TournamentConfigurationProps> = React.memo(
  ({ features, tournamentId }) => {
    const { t } = useTranslation()
    const [setFeature, { loading }] = useMutation<
        SetTournamentFeature,
        SetTournamentFeatureVariables
      >(SET_TOURNAMENT_FEATURE, { client: tournamentsClient })
    const isGlobalAdmin = useMemo(userIsGlobalAdmin, [])
  
    if (!features?.length || !isGlobalAdmin) return null

    const sortedFeatures = useMemo(
      () => [...features].sort((a, b) => (a.featureId < b.featureId ? -1 : 1)),
      [features]
    )
  
    return (
      <Grid item xs={12} md={6} className={styles.sizedGrid}>
        <Panel title={t('tournament configuration')} spacing={{}}>
          <SizedGridContent>
            {sortedFeatures.map(f => (
              <Switch
                data-testid={`feature-${f.featureId}`}
                key={f.featureId}
                checked={f.enabled}
                onChange={() =>
                  setFeature({
                    variables: { tournamentId, featureId: f.featureId, enabled: !f.enabled }
                  })
                }
                label={t(`tournament feature ${f.featureId}`.toLowerCase())}
                disabled={!f.overridable || loading}
              />
            ))}
          </SizedGridContent>
        </Panel>
      </Grid>
    )
  }
)

export default TournamentConfiguration