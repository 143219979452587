// extracted by mini-css-extract-plugin
export var bodyBase = "tournament-info-module--body-base--oT0qJ tournament-info-module--site-font--gLVif";
export var bodyLarge = "tournament-info-module--body-large--DB-HI tournament-info-module--body-base--oT0qJ tournament-info-module--site-font--gLVif";
export var bodyLargeBold = "tournament-info-module--body-large-bold--tjZMN tournament-info-module--body-base--oT0qJ tournament-info-module--site-font--gLVif";
export var bodyRegular = "tournament-info-module--body-regular--0mvA3 tournament-info-module--body-base--oT0qJ tournament-info-module--site-font--gLVif";
export var bodyRegularBold = "tournament-info-module--body-regular-bold--ibP2b tournament-info-module--body-base--oT0qJ tournament-info-module--site-font--gLVif";
export var bodySmall = "tournament-info-module--body-small--iAHZE tournament-info-module--body-base--oT0qJ tournament-info-module--site-font--gLVif";
export var bodySmallBold = "tournament-info-module--body-small-bold--q0zK4 tournament-info-module--body-base--oT0qJ tournament-info-module--site-font--gLVif";
export var borderTop = "tournament-info-module--border-top--SLkjn";
export var breakWordContainer = "tournament-info-module--break-word-container--4dU73";
export var buttonIconBase = "tournament-info-module--button-icon-base--1mqwe";
export var clickLink = "tournament-info-module--click-link--SnG2L";
export var dropdownBase = "tournament-info-module--dropdown-base--tkoHY";
export var dropdownSelectBase = "tournament-info-module--dropdown-select-base--aazs7 tournament-info-module--text-input--8ye2k";
export var externalLinkIcon = "tournament-info-module--external-link-icon--ROess";
export var externalLinkLabel = "tournament-info-module--external-link-label--FKs-W";
export var flexCol = "tournament-info-module--flex-col--2oU0l";
export var formErrorMessage = "tournament-info-module--form-error-message--beqce";
export var h3 = "tournament-info-module--h3--dmlc7";
export var h4 = "tournament-info-module--h4--UBMxU";
export var hoverLink = "tournament-info-module--hover-link--MAiGs";
export var hoverRow = "tournament-info-module--hover-row--WZF-+";
export var membershipContainer = "tournament-info-module--membership-container--1j58O tournament-info-module--flex-col--2oU0l tournament-info-module--primary-border--+6Y1V";
export var membershipHeader = "tournament-info-module--membership-header--SV3E4";
export var membershipHeading = "tournament-info-module--membership-heading--lOMH1";
export var membershipLabel = "tournament-info-module--membership-label--1rq0Q";
export var modalContent = "tournament-info-module--modal-content--gFUZc";
export var moreFiltersBorderClass = "tournament-info-module--more-filters-border-class--5Ur3z";
export var pageBg = "tournament-info-module--page-bg--47kRd";
export var panelSubtitle = "tournament-info-module--panel-subtitle--wZAxI";
export var pointer = "tournament-info-module--pointer--itfpI";
export var priceForm = "tournament-info-module--price-form--SJS9B";
export var primaryBorder = "tournament-info-module--primary-border--+6Y1V";
export var shadowBoxLight = "tournament-info-module--shadow-box-light--dPKK5";
export var siteFont = "tournament-info-module--site-font--gLVif";
export var slideDownAndFade = "tournament-info-module--slideDownAndFade--jJglE";
export var slideLeftAndFade = "tournament-info-module--slideLeftAndFade--CwS4T";
export var slideRightAndFade = "tournament-info-module--slideRightAndFade--15YzD";
export var slideUpAndFade = "tournament-info-module--slideUpAndFade--0Gl72";
export var statusDecoration = "tournament-info-module--status-decoration--4HATN";
export var textInput = "tournament-info-module--text-input--8ye2k";
export var textInverted = "tournament-info-module--text-inverted--+orXQ";
export var textMediumDark = "tournament-info-module--text-medium-dark--O2M+C";
export var tooltipFont = "tournament-info-module--tooltipFont--ttgXb";
export var unstyledButton = "tournament-info-module--unstyled-button--0jfvO";