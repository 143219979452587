import { useMutation } from '@apollo/client'
import { navigate } from 'gatsby'
import React, { useCallback, useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { tournamentsClient } from 'src/apollo/client'
import Modal, { ModalButton } from 'src/components/modal/modal'
import { getClientConfig } from 'src/config/config'
import { GetEvents_tournament as Tournament } from 'src/graphql-types/GetEvents'
import {
  ResetSanctionStatus,
  ResetSanctionStatusVariables
} from 'src/graphql-types/ResetSanctionStatus'
import Alert from '../alert/alert'
import { RESET_SANCTION_STATUS } from '../events/events-queries'
import Icon from '../icon/icon'
import Spinner from '../spinner/spinner'
import { BodyLarge, H4 } from '../typography/typography'
import * as styles from './request-changes-modal.module.less'

interface RequestChangesModalProps {
  orgId?: string
  tournament?: Tournament
  showModal?: boolean
  cancelButton?: ModalButton
  onVisibilityChange?: (visible: boolean) => void
}

const RequestChangesModal: React.FC<RequestChangesModalProps> = ({
  orgId,
  tournament,
  cancelButton,
  showModal = false,
  onVisibilityChange
}) => {
  const [message, setMessage] = useState<string>('')

  const onChangeMessage = useCallback(
    (e: React.ChangeEvent<HTMLTextAreaElement>) => {
      setMessage(e.target.value)
    },
    [setMessage]
  )

  const [resetSanctionStatus, { loading, error }] = useMutation<
    ResetSanctionStatus,
    ResetSanctionStatusVariables
  >(RESET_SANCTION_STATUS, { client: tournamentsClient })
  const requestChanges = useCallback(() => {
    const reset = async () => {
      const { data } = await resetSanctionStatus({
        variables: { orgId, tournamentId: tournament?.id, message }
      })
      if (data) {
        navigate('/tournaments')
      }
    }
    reset()
  }, [resetSanctionStatus, orgId, tournament, message])

  const { t } = useTranslation()
  return (
    <Modal
      cancelButton={cancelButton}
      actionButtons={
        loading
          ? []
          : [
              {
                id: 'request changes',
                content: t('request changes'),
                props: { onClick: requestChanges }
              }
            ]
      }
      title={t('requesting tourn changes')}
      openButton={{
        props: {
          level: 'tertiary',
          spacing: { margins: { sm: 'right' } }
        },
        hidden: true,
        content: (
          <>
            <Icon name="sm-reset" className={styles.requestChangesIcon} />
            {t('request changes')}
          </>
        )
      }}
      onVisibilityChange={onVisibilityChange}
      show={showModal}
    >
      <div className={styles.requestChangesModal}>
        {loading && (
          <div className={styles.requestChangesLoading}>
            <Spinner />
            <BodyLarge spacing={{ margins: { md: 'top' } }}>
              {t('unsubmitting tournament')}
            </BodyLarge>
          </div>
        )}
        {error && error.message}
        {!loading && !error && (
          <>
            <Alert>{t('tourn changes info')}</Alert>
            <div className={styles.emailMessage}>
              <Trans
                i18nKey="tourn changes email"
                values={{
                  clientName: getClientConfig().clientName,
                  tournamentName: tournament?.name,
                  directorName: `${tournament?.director?.firstName} ${tournament?.director?.lastName}`,
                  level: tournament?.level?.name
                }}
                components={{
                  title: <H4 />,
                  email: <BodyLarge spacing={{ margins: { xs: 'top' } }} />
                }}
              />
            </div>

            <textarea
              value={message}
              onChange={onChangeMessage}
              placeholder={t('describe requested changes')}
              className={styles.requestChangesMessage}
            />
            <BodyLarge>{t('cannot be undone')}</BodyLarge>
          </>
        )}
      </div>
    </Modal>
  )
}

export default RequestChangesModal
