import { GetEvents, GetEvents_tournament_events as Event } from 'src/graphql-types/GetEvents'
import { useMemo } from 'react'
import { Gender as GenderType, EventType, FamilyType } from 'src/graphql-types/globalTournamentTypes'
const { BOYS, GIRLS, MIXED, COED } = GenderType
const { SINGLES, DOUBLES, TEAM } = EventType
const {
  GRANDFATHER_GRANDSON,
  HUSBAND_WIFE,
  SAME_GENDER_FEMALE,
  MOTHER_DAUGHTER,
  SAME_GENDER_MALE,
  FATHER_DAUGHTER,
  FATHER_SON,
  MOTHER_SON
} = FamilyType

type CompareType = 'gender' | 'eventType' | 'familyType'

/*
 * Sorting Order:
 *
 * - Standard Events
 *    - Gender
 *      - Male
 *      - Female
 *    - Age
 *      - No Limits (Open)
 *      - Min Age (ASC)
 *      - Max Age (ASC)
 *    - Event Type
 *      - Singles
 *      - Doubles
 *      - Team
 * - NTRP Events
 *    - All standard fields
 *    - Ranking (ASC)
 * - Family Events
 *    - Family Type: see familyTypeWeights
 *    - Age (ASC)
 *
 */

class WeightComparer {
  private genderWeights = [BOYS, GIRLS, MIXED, COED]
  private eventTypeWeights = [SINGLES, DOUBLES, TEAM]
  private familyTypeWeights = [
    null,
    FATHER_SON,
    FATHER_DAUGHTER,
    MOTHER_SON,
    MOTHER_DAUGHTER,
    HUSBAND_WIFE,
    GRANDFATHER_GRANDSON,
    SAME_GENDER_FEMALE,
    SAME_GENDER_MALE
  ]
  private events: [Event, Event]
  constructor(a: Event, b: Event) {
    this.events = [a, b]
  }
  compare(type: CompareType) {
    const { weights, getField } = this.getWeights(type)
    const [aWeight, bWeight] = this.events.map(e => weights.findIndex(w => w === getField(e)))
    return aWeight > bWeight ? 1 : -1
  }
  private getWeights(
    c: CompareType
  ): { weights: (string | null)[]; getField: (e: Event) => string | null } {
    if (c === 'gender') return { weights: this.genderWeights, getField: this.getGender }
    if (c === 'eventType') return { weights: this.eventTypeWeights, getField: this.getEventType }
    return { weights: this.familyTypeWeights, getField: this.getFamilyType }
  }
  private getGender(e: Event) {
    return e.division.gender
  }
  private getEventType(e: Event) {
    return e.division.eventType
  }
  private getFamilyType(e: Event) {
    return e.division.familyType
  }
}

const useEventSort = (data?: GetEvents) => {
  return useMemo(() => {
    if (data?.tournament?.events) {
      return [...data.tournament.events].sort((eventA, eventB) => {
        const comparer = new WeightComparer(eventA, eventB)
        const [a, b] = [eventA, eventB].map(e => ({
          minAge: e.division.ageCategory?.minimumAge,
          maxAge: e.division.ageCategory?.maximumAge,
          gender: e.division.gender,
          rating: e.division.ratingCategory?.value,
          eventType: e.division.eventType,
          familyType: e.division.familyType
        }))
        // null is the highest weight here, if no family type it comes first
        if (a.familyType !== b.familyType) {
          return comparer.compare('familyType')
        }
        // at this point, only check if there a rating present, standard events trump rated events
        else if (a.rating !== b.rating && (a.rating === undefined || b.rating === undefined)) {
          return a.rating === undefined ? -1 : 1
        } else if (a.gender !== b.gender) {
          return comparer.compare('gender')
        } else if (a.minAge !== b.minAge) {
          if (a.minAge && b.minAge) return a.minAge > b.minAge ? 1 : -1
          else if (a.maxAge && b.maxAge) return a.maxAge > b.maxAge ? 1 : -1
          // These next two are for 'open' tournaments with no min or max which trump age limits.
          else if (a.minAge || b.minAge) return b.minAge ? -1 : 1
          else if (a.maxAge || a.maxAge) return b.maxAge ? -1 : 1
        } else if (a.eventType !== b.eventType) {
          return comparer.compare('eventType')
        }
        // now check the actual value of the rating if it is present, higher ratings first
        // due to the earlier statement, ratings here will either both be present or both undefined
        else if (a.rating !== b.rating) {
          return (a.rating ?? 0) > (b.rating ?? 0) ? 1 : -1
        }
        return 0
      })
    }
  }, [data])
}

export default useEventSort
