import { useMutation } from '@apollo/client'
import { Form, Formik, useFormikContext } from 'formik'
import { navigate } from 'gatsby'
import moment from 'moment'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { TextField } from 'src/components/formik-fields/formik-fields'
import { DeleteTournament, DeleteTournamentVariables } from 'src/graphql-types/DeleteTournament'
import {
  GetEvents_tournament as Tournament,
  GetEvents_tournament_tournamentFeePayment as TournamentFeePayment
} from 'src/graphql-types/GetEvents'
import { TournamentFeePaymentSatus } from 'src/graphql-types/globalTournamentTypes'
import {
  UpdateTournamentFee,
  UpdateTournamentFeeVariables
} from 'src/graphql-types/UpdateTournamentFee'
import { useIsSanctioningBody, userIsGlobalAdmin } from 'src/utils/auth'
import ROUTES from 'src/utils/routes'
import * as Yup from 'yup'
import APIErrorMessage from '../api-error-message/api-error-message'
import InfoPanel, { InfoNugget } from '../info-panel/info-panel'
import ButtonModal from '../modal/modal'
import StatusLabel, { LabelVariety } from '../status-label/status-label'
import Modal from '../modal/modal'
import SanctionStatusLabel from '../sanction-status-label/sanction-status-label'
import { Body, BodyLarge } from '../typography/typography'
import { NoWrap } from '../util-components/util-components'
import {
  CANCEL_TOURNAMENT,
  DELETE_TOURNAMENT,
  UPDATE_TOURNAMENT_FEE,
  UPDATE_TOURNAMENT_VISIBILITY
} from './tournament-info-queries'
import * as styles from './tournament-info.module.less'
import ExpandedMenu from '../expanded-menu/expanded-menu'
import {
  UpdateTournamentVisibility,
  UpdateTournamentVisibilityVariables
} from 'src/graphql-types/UpdateTournamentVisibility'
import Spinner from '../spinner/spinner'
import { ButtonProps } from '../button/button'
import Icon from '../icon/icon'
import { getEnvConfig } from 'src/config/config'
import { tournamentsClient } from 'src/apollo/client'
import { CancelTournament, CancelTournamentVariables } from 'src/graphql-types/CancelTournament'

enum TournamentVisibilityMask {
  None = 0,
  HideFromPublicSearch = 1 << 0
}

interface Props {
  loading?: boolean
  tournament?: Tournament
}

interface FormValues {
  tournamentFee: number
}

const TournamentInfo: React.FC<Props> = ({ tournament: tourn, loading }) => {
  const { t } = useTranslation()
  const isSanctioningBody = useIsSanctioningBody(tourn?.id)

  const hiddenFromSearch = useMemo(
    () =>
      !!(
        (tourn?.visibilityMask ?? TournamentVisibilityMask.None) &
        TournamentVisibilityMask.HideFromPublicSearch
      ),
    [tourn]
  )

  const clearTournament = useCallback(() => {
    const store = tournamentsClient.cache.extract()
    for (const prop in store) {
      if (prop.includes(`Tournament:${tourn?.id}`)) {
        delete store[prop]
      }
    }
  }, [tourn])

  const editTournament = useCallback(() => {
    clearTournament()
    if (tourn?.id) navigate(`/tournaments/edit-tournament/${tourn.id}`)
  }, [clearTournament, tourn])

  const [
    deleteTournament,
    { loading: deletingTournament, data: deleteData, error: deleteError }
  ] = useMutation<DeleteTournament, DeleteTournamentVariables>(DELETE_TOURNAMENT, {
    client: tournamentsClient,
    variables: { id: tourn?.id }
  })

  const [
    updateTournamentVisibility,
    { loading: visibilityUpdating, data: visibiltyData, error: visibilityError }
  ] = useMutation<UpdateTournamentVisibility, UpdateTournamentVisibilityVariables>(
    UPDATE_TOURNAMENT_VISIBILITY,
    {
      client: tournamentsClient,
      variables: {
        id: tourn?.id,
        visibility: hiddenFromSearch
          ? TournamentVisibilityMask.None
          : TournamentVisibilityMask.HideFromPublicSearch
      }
    }
  )

  const [
    cancelTournament,
    { loading: cancelingTournament, error: cancelTournamentError }
  ] = useMutation<CancelTournament, CancelTournamentVariables>(CANCEL_TOURNAMENT, {
    client: tournamentsClient,
    variables: { tournamentId: tourn?.id }
  })

  useEffect(() => {
    if (deleteData) {
      clearTournament()
      navigate(ROUTES.TOURNAMENTS)
    }
  }, [deleteData, clearTournament])

  const [showDeleteModal, setShowDeleteModal] = useState(false)
  const [showVisibilityModal, setShowVisibilityModal] = useState(false)
  const [showCancelModal, setShowCancelModal] = useState(false)

  useEffect(() => {
    if (visibiltyData) setShowVisibilityModal(false)
  }, [visibiltyData, setShowVisibilityModal])

  const isGlobalAdmin = useMemo(userIsGlobalAdmin, [])

  const openInNewTab = useCallback((url: string) => {
    // Most secure way, avoids new window having access to our window
    const newWindow = window.open(url, '_blank', 'noopener,noreferrer')
    if (newWindow) newWindow.opener = null
  }, [])

  const launchTournamentDesk = useCallback(() => {
    if (tourn?.id) openInNewTab(`${getEnvConfig().TOURNAMENT_DESK_URL}/tournament/${tourn.id}`)
  }, [openInNewTab, tourn])

  const launchPublicSite = useCallback(() => {
    const id = tourn?.id
    const urlSegment = tourn?.organisation?.urlSegment
    const queryString = tourn?.isPublished ? '' : '?previewMode=true'
    if (id && urlSegment) {
      openInNewTab(
        `${
          getEnvConfig().CLUBSPARK_CLASSIC_ADMIN_SITE // not admin, just prematurely named variable...
        }/competitions/${urlSegment}/Tournaments/overview/${id}${queryString}`
      )
    }
  }, [openInNewTab, tourn])

  return (
    <>
      <InfoPanel
        title={`${tourn?.level?.name ?? ''} - ${tourn?.name}`}
        subtitle={
          tourn && (
            <PanelSubtitle
              isPublished={tourn.isPublished}
              isCanceled={tourn?.isCancelled}
              orgName={tourn.organisation?.name}
              hiddenFromSearch={hiddenFromSearch}
              visibilityUpdating={visibilityUpdating}
            />
          )
        }
        loading={loading}
        image={tourn?.websiteContent?.logoPath || undefined}
        customEndContent={
          <ExpandedMenu
              items={[
                {
                  key: 'desk',
                  label: <ExternalLinkLabel label={t('launch tournament desk')} />,
                  onClick: launchTournamentDesk,
                  hide: !tourn?.finalisedAndCharged
                },
                {
                  key: 'website',
                  label: <ExternalLinkLabel label={t('view tournament website')} />,
                  onClick: launchPublicSite
                },
                {
                  key: 'edit',
                  label: t('edit tournament'),
                  onClick: editTournament,
                  hide: !isSanctioningBody
                },
                {
                  key: 'hide',
                  label: hiddenFromSearch ? t('unhide tournament') : t('hide tournament'),
                  onClick: hiddenFromSearch
                    ? updateTournamentVisibility
                    : () => setShowVisibilityModal(true),
                  hide: !isSanctioningBody
                },
                {
                  key: 'cancel',
                  label: t('cancel tournament'),
                  onClick: () => setShowCancelModal(true),
                  hide: !isSanctioningBody || tourn?.isCancelled
                },
                {
                  key: 'delete',
                  label: t('delete tournament'),
                  onClick: () => setShowDeleteModal(true),
                  hide: !isGlobalAdmin,
                  warning: true
                }
              ]}
              buttonText={t('tournament actions')}
              buttonIcon
              iconName="sm-down"
              anchorElement="button"
            />
        }
      >
        {tourn && (
          <>
            <InfoNugget title={`${t('tournament director')}:`}>
              {tourn?.director
                ? `${tourn.director.firstName} ${tourn.director.lastName}`
                : t('n/a')}
            </InfoNugget>
            {tourn?.lastSanctionStatusChange?.createdByFirstName &&
              tourn?.lastSanctionStatusChange?.createdByLastName && (
                <InfoNugget
                  title={`${t('submitted by')}:`}
                >{`${tourn?.lastSanctionStatusChange?.createdByFirstName} ${tourn?.lastSanctionStatusChange?.createdByLastName}`}</InfoNugget>
              )}
            {tourn.lastSanctionStatusChange?.createdAt && (
              <InfoNugget title={`${t('submitted on')}:`}>
                {t('submitted on date', {
                  date: moment(tourn.lastSanctionStatusChange?.createdAt)
                })}
              </InfoNugget>
            )}
            <InfoNugget title={`${t('location')}:`}>{`${tourn?.primaryLocation?.name}`}</InfoNugget>
            <InfoNugget title={`${t('dates')}:`}>
              {t('title dates', {
                dates: {
                  start: moment(tourn.timings.startDate),
                  end: moment(tourn.timings.endDate)
                }
              })}
            </InfoNugget>
            <InfoNugget title={t('tournament fee')}>
              <ChangeFee tournament={tourn} />
            </InfoNugget>
            <InfoNugget title={t('status')}>
              <SanctionStatusLabel status={tourn.sanctionStatus} />
            </InfoNugget>
            <InfoNugget title={t('payment status')}>
              <PaymentStatusLabel paymentInfo={tourn.tournamentFeePayment} />
            </InfoNugget>
          </>
        )}
      </InfoPanel>
      <ConfirmationModal
        title={t('delete tournament')}
        show={showDeleteModal}
        loading={deletingTournament}
        onConfirm={deleteTournament}
        setShow={setShowDeleteModal}
        error={deleteError?.message}
        confirmInfo={t('delete tournament info')}
        level="warning"
      />
      <ConfirmationModal
        title={t('hide tournament')}
        show={showVisibilityModal}
        loading={visibilityUpdating}
        onConfirm={updateTournamentVisibility}
        setShow={setShowVisibilityModal}
        error={visibilityError?.message}
        confirmInfo={t('hide tournament info')}
      />
      <ConfirmationModal
        title={t('cancel tournament')}
        show={showCancelModal}
        loading={cancelingTournament}
        onConfirm={async () => {
          await cancelTournament()
          setShowCancelModal(false)
        }}
        setShow={setShowCancelModal}
        error={cancelTournamentError?.message}
        confirmInfo={t('cancel tournament info')}
      />
    </>
  )
}

interface ExternalLinkLabelProps {
  label: string
}

const ExternalLinkLabel: React.FC<ExternalLinkLabelProps> = ({ label }) => {
  return (
    <div className={styles.externalLinkLabel}>
      {label}
      <Icon name={'md-new-window'} className={styles.externalLinkIcon} />
    </div>
  )
}

interface PaymentStatusLabelProps {
  paymentInfo: TournamentFeePayment | null
}

const PaymentStatusLabel: React.FC<PaymentStatusLabelProps> = ({ paymentInfo }) => {
  const { t } = useTranslation()

  if (!paymentInfo) return t('n/a')

  const { status, latestChargeTimestamp } = paymentInfo
  let variety: LabelVariety = 'warning'
  let statusText = t('not paid')

  if (status === TournamentFeePaymentSatus.PAID) {
    variety = 'success'
    statusText = `${t('paid')} ${
      latestChargeTimestamp
        ? `- ${t('tournament payment date', { date: moment(latestChargeTimestamp) })}`
        : ''
    }`
  } else if (status === TournamentFeePaymentSatus.NOT_REQUIRED) {
    variety = 'success'
    statusText = t('not required')
  }
  return <StatusLabel variety={variety}>{statusText}</StatusLabel>
}

interface PanelSubtitleProps {
  orgName?: string
  hiddenFromSearch?: boolean
  visibilityUpdating?: boolean
  isPublished?: boolean
  isCanceled?: boolean
}

const PanelSubtitle: React.FC<PanelSubtitleProps> = ({
  orgName,
  visibilityUpdating,
  hiddenFromSearch,
  isPublished,
  isCanceled
}) => {
  const { t } = useTranslation()

  return (
    <div className={styles.panelSubtitle}>
      <StatusLabel
        spacing={{ margins: { sm: 'right' } }}
        variety={isPublished ? 'success' : 'neutral2'}
      >
        {isPublished ? t('published') : t('not published')}
      </StatusLabel>
      {isCanceled && (
        <StatusLabel spacing={{ margins: { sm: 'right' } }} variety={'error'}>
          {t('canceled')}
        </StatusLabel>
      )}
      {(hiddenFromSearch || visibilityUpdating) && (
        <StatusLabel spacing={{ margins: { sm: 'right' } }} variety={'neutral2'}>
          {visibilityUpdating ? <Spinner fluid /> : t('hidden')}
        </StatusLabel>
      )}
      {orgName}
    </div>
  )
}

interface ChangeFeeProps {
  tournament: Tournament
}

const ChangeFee: React.FC<ChangeFeeProps> = ({
  tournament: { id, tournamentFee: fee, sanctionStatus }
}) => {
  const { t } = useTranslation()
  const [updateFee, { error, data }] = useMutation<
    UpdateTournamentFee,
    UpdateTournamentFeeVariables
  >(UPDATE_TOURNAMENT_FEE, { client: tournamentsClient })

  const initialValues = useMemo<FormValues>(() => ({ tournamentFee: fee ? fee / 100 : 0 }), [fee])

  const validationSchema = useMemo(() => {
    return Yup.object().shape({
      tournamentFee: Yup.number()
        .required(t('enter a value'))
        .test(
          'free-or-min',
          t('fee zero or min', { min: (0.5).toFixed(2) }),
          v => v >= 0.5 || v === 0
        )
    })
  }, [t])

  const onSubmit = useCallback(
    ({ tournamentFee }: FormValues) => {
      return updateFee({ variables: { newFee: tournamentFee * 100, tournamentId: id } })
    },
    [updateFee]
  )

  return (
    <Formik validationSchema={validationSchema} initialValues={initialValues} onSubmit={onSubmit}>
      <>
        <NoWrap>
          {t('currency sign')} {(fee ? fee / 100 : 0).toFixed(2)}
        </NoWrap>
        {sanctionStatus === 'SUBMITTED' && (
          <ChangeFeeForm error={error?.message} success={!!data} />
        )}
      </>
    </Formik>
  )
}

interface ChangeFeeForm {
  error?: string
  success?: boolean
}

const ChangeFeeForm: React.FC<ChangeFeeForm> = ({ error, success }) => {
  const { submitForm, isValid, resetForm, isSubmitting } = useFormikContext()
  const { t } = useTranslation()

  const [show, setShow] = useState(false)
  useEffect(() => {
    if (success) setShow(false)
  }, [success, setShow])

  return (
    <Modal
      show={show}
      title={t('change tournament fee')}
      openButton={{
        content: t('change'),
        props: { spacing: { margins: { xxs: 'left' } }, size: 'xs', linkStyle: true }
      }}
      cancelButton
      actionButtons={[
        {
          content: t('change'),
          props: { type: 'submit', onClick: submitForm, disabled: !isValid, loading: isSubmitting },
          id: 'change'
        }
      ]}
      onVisibilityChange={visible => {
        if (!visible) resetForm()
        setShow(visible)
      }}
    >
      <ModalContent>
        <BodyLarge>{t('change tournament fee info')}</BodyLarge>
        <Form className={styles.priceForm}>
          <TextField
            currencyInput
            inlineLabel={t('set new price')}
            name={'tournamentFee'}
            disabled={isSubmitting}
          />
        </Form>
        <APIErrorMessage error={error} />
      </ModalContent>
    </Modal>
  )
}

interface ConfirmationModalProps {
  show: boolean
  setShow: (show: boolean) => void
  loading: boolean
  onConfirm: () => void
  error?: string
  confirmInfo: string
  title: string
  level?: ButtonProps['level']
}

const ConfirmationModal: React.FC<ConfirmationModalProps> = ({
  show,
  loading,
  onConfirm,
  setShow,
  confirmInfo,
  title,
  error,
  level
}) => {
  const { t } = useTranslation()
  return (
    <ButtonModal
      openButton={{ hidden: true }}
      show={show}
      onVisibilityChange={setShow}
      title={title}
      cancelButton
      actionButtons={[
        {
          id: 'confirm',
          content: title,
          props: {
            level,
            loading,
            onClick: () => onConfirm()
          }
        }
      ]}
    >
      <ModalContent>
        <Body p size={'lg'}>
          {confirmInfo}
        </Body>
        <Body p bold size={'lg'} spacing={{ margins: { md: 'top' } }}>
          {t('continue question')}
        </Body>
        {error && <APIErrorMessage error={error} />}
      </ModalContent>
    </ButtonModal>
  )
}

const ModalContent: React.FC = ({ children }) => {
  return <div className={styles.modalContent}>{children}</div>
}

export default TournamentInfo
