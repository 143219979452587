import gql from 'graphql-tag'

export const GET_TOURNAMENT_FOR_EMAIL_TEMPLATE = gql`
  query GetTournamentForEmailTemplate($id: UUID!) {
    tournament(id: $id) {
      id
      name
      director {
        id
        firstName
      }
      primaryLocation {
        id
        name
        sectionName
      }
      level {
        id
        name
      }
      timings {
        startDate
        endDate
        timeZone
      }
    }
  }
`
