import React from 'react'
import { NoWrap } from '../util-components/util-components'
import { Checkbox } from '@material-ui/core'
import Icon, { IconProps } from 'src/components/icon/icon'
import { useTranslation } from 'react-i18next'
import * as styles from './approval-buttons.module.less'


interface ApprovalButtonsProps {
  id: string
  disableDecline?: boolean
  disableApprove?: boolean
  approved?: boolean
  updateApproval: (approved?: boolean) => any
}

const ApprovalButtons: React.FC<ApprovalButtonsProps> = ({
  approved,
  updateApproval,
  id,
  disableApprove,
  disableDecline
}) => {
  const { t } = useTranslation()

  return (
    <NoWrap>
      <label className={disableApprove ? styles.approveCheckboxDisabled : ''}>
        <Checkbox
          disableRipple
          disabled={disableDecline || disableApprove}
          icon={<CheckboxIcon disabled={disableApprove} name="md-tick-circle" />}
          checkedIcon={<Icon name="md-tick-circle" className={styles.approveChecked} />}
          name={id}
          onChange={e => updateApproval(e.target.checked)}
          checked={approved === true}
        />
        {t('approve')}
      </label>
      <label className={disableDecline ? styles.declineCheckboxDisabled : styles.declineCheckbox}>
        <Checkbox
          disabled={disableDecline || disableApprove}
          icon={<CheckboxIcon disabled={disableDecline} name="md-close-circle" />}
          checkedIcon={<Icon name="md-close-circle" className={styles.declineChecked} />}
          name={id}
          checked={approved === false}
          onChange={e => updateApproval(!e.target.checked)}
        />
        {t('decline')}
      </label>
    </NoWrap>
  )
}

interface CheckboxIconProps extends IconProps {
  disabled?: boolean
}

const CheckboxIcon: React.FC<CheckboxIconProps> = ({ disabled, ...props }) => {
  return (
    <Icon className={disabled ? styles.checkboxIconDisabled : styles.checkboxIcon} {...props} />
  )
}

export default ApprovalButtons